import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { Chat as ChatIcon } from "@material-ui/icons";
import styled from "styled-components";
import { withAlert } from "react-alert";
import { withTranslation } from "react-i18next";
import ChannelsColumn from "./components/ChannelsColumn";
import ChatListColumn from "./components/ChatListColumn";
import MessagesColumn from "./components/MessagesColumn";
import NewChannelColumn from "./components/NewChannelColumn";
import NewChatColumn from "./components/NewChatColumn";
import RocketChatWS from "../../utils/RocketChatWS";
import ChatService from "../../services/ChatService";

const ChatColumn = styled(Grid)`
  background-color: white;
  border: 1px solid ${({ theme }) => theme.color.background.default};
  max-height: 700px;
`;

const NoChatColumn = styled(Grid)`
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid ${({ theme }) => theme.color.background.default};
  padding: 0 58px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
  line-height: 1.26;
  p {
    font-size: 19px;
  }
`;

const isMobile = window.innerWidth <= 784;

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedChannel: null,
      selectedChat: null,
      lastSelectedChannel: null,
      showChannelForm: false,
      showChatForm: false,
      currentSection: "channelList"
    };
  }

  componentDidMount() {
    const { setTitle, t } = this.props;
    setTitle(t("messages.title", "Central de Mensagens"));

    this.setupWebSocket();
    document.addEventListener("refresh", this.refresh, false);
  }

  componentWillUnmount() {
    document.removeEventListener("refresh", this.refresh, false);
    RocketChatWS.close();
  }

  refresh = () => {
    this.setState({
      showChannelForm: false,
      showChatForm: false
    });
  };

  setupWebSocket = async () => {
    try {
      const authData = await ChatService.authenticate();
      const { token, userId } = authData;
      let { url } = authData;
      localStorage.setItem(
        "rocketChatInfo",
        btoa(JSON.stringify({ ...authData, url }))
      );

      url = `${url.replace(new RegExp("(?:http)"), "ws")}/websocket`;
      await RocketChatWS.open(url, token);

      // TODO Fazer receptor para tratar o contador
      RocketChatWS.sendSubUser(userId);
    } catch (e) {
      console.error(e);
      const { alert } = this.props;
      alert.show(`Erro ao autenticar usuário do chat`, { title: "Erro" });
    }
  };

  onSelectChannel = async (channel, editMode) => {
    this.setState({
      selectedChannel: channel,
      showChannelForm: editMode,
      selectedChat: null,
      currentSection: "chatList"
    });
  };

  onSelectChat = async chat => {
    const newChat = {
      id: chat.id,
      rocketChatId: chat.rocketChatId,
      type: chat.type,
      photo: chat.members[0].photo,
      title:
        chat.type === "private"
          ? chat.members.find(member => member.type === "family_member").name
          : `Grupo - ${chat.student.name}`,
      studentId: chat.student.id,
      studentName: chat.student.name,
      studentInfos: `
                    RA: ${chat.student.ra}
                  `,
      typeMember: chat.members[0].typeMember,
      studentGrade: chat.student.grade ? `Turma: ${chat.student.grade}` : null,
      studentGradeClass: chat.student.gradeClass
        ? `Série: ${chat.student.gradeClass}`
        : null
    };
    this.setState({ selectedChat: newChat, currentSection: "messages" });
  };

  render() {
    const {
      selectedChannel,
      selectedChat,
      showChannelForm,
      showChatForm,
      lastSelectedChannel,
      currentSection
    } = this.state;

    const { t } = this.props;

    return (
      <div style={{ maxHeight: 700 }} id="chat-container">
        <Grid container>
          <ChatColumn item xs={isMobile ? 12 : 4}>
            <ChannelsColumn
              onSelect={this.onSelectChannel}
              currentSection={currentSection}
              onClickAdd={() =>
                this.setState({
                  showChannelForm: true,
                  lastSelectedChannel: selectedChannel,
                  selectedChannel: null,
                  currentSection: "channelForm"
                })
              }
            />
          </ChatColumn>

          {showChannelForm && (
            <ChatColumn item xs={isMobile ? 12 : 8}>
              <NewChannelColumn
                currentSection={currentSection}
                selectedChannel={selectedChannel && selectedChannel.id}
                onClose={() =>
                  this.setState({
                    showChannelForm: false,
                    selectedChannel: lastSelectedChannel,
                    currentSection: "channelList"
                  })
                }
              />
            </ChatColumn>
          )}

          {!showChannelForm && selectedChannel && (
            <ChatColumn item xs={isMobile ? 12 : 4}>
              <ChatListColumn
                currentSection={currentSection}
                channelId={selectedChannel.id}
                onSelect={this.onSelectChat}
                onClickAdd={() =>
                  this.setState({
                    showChatForm: true,
                    currentSection: "chatForm"
                  })
                }
                onClose={() =>
                  this.setState({
                    currentSection: "channelList"
                  })
                }
              />
            </ChatColumn>
          )}

          {!showChannelForm && showChatForm && (
            <ChatColumn item xs={isMobile ? 12 : 4}>
              <NewChatColumn
                currentSection={currentSection}
                onClose={() =>
                  this.setState({
                    showChatForm: false,
                    currentSection: "chatList"
                  })
                }
                selectedChannel={selectedChannel.id}
                allowGroup={selectedChannel.allowGroup}
              />
            </ChatColumn>
          )}

          {!showChannelForm && !showChatForm && selectedChat && (
            <ChatColumn item xs={isMobile ? 12 : 4}>
              <MessagesColumn
                chat={selectedChat}
                currentSection={currentSection}
                selectedChannelName={selectedChannel.name}
                onClose={() =>
                  this.setState({
                    currentSection: "chatList"
                  })
                }
              />
            </ChatColumn>
          )}

          {!isMobile && !showChannelForm && !showChatForm && !selectedChat && (
            <NoChatColumn item xs={4}>
              <ChatIcon fontSize="large" style={{ fontSize: 42 }} />
              <p>
                {t(
                  "messages.select_conversation_to_view_messages",
                  "Selecione uma conversa para visualizar as mensagens."
                )}
              </p>
            </NoChatColumn>
          )}
        </Grid>
      </div>
    );
  }
}

Chat.propTypes = {
  setTitle: PropTypes.func.isRequired,
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(withAlert()(Chat));
