import React, { useState } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { IconButton, Box, Checkbox } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import RoundedAvatar from "../../../waybee-ui/RoundedAvatar";
import StudentInfoPopover from "../../../components/StudentInfoPopover";

const Container = styled.div`
  position: relative;
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid ${({ theme }) => theme.color.background.default};
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 50%;
    `};

  & * {
    white-space: nowrap;
    overflow: hidden;
  }
`;
const MemberInfo = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-left: 12px;
`;
const Name = styled.p`
  margin: 0;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
  width: 100%;
  text-overflow: ellipsis;
  ${props =>
    props.memberType
      ? ":hover {\n    text-decoration: underline;\n    cursor: pointer;\n  }"
      : ""}
`;
const TypeMember = styled.p`
  font-size: 14px;
  margin: 8px 0;
  color: ${({ theme }) => theme.color.primary.main};
  text-overflow: ellipsis;
  white-space: pre-wrap;
`;
const AdditionalInfo = styled.p`
  margin: 0;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
`;
const Relation = styled.p`
  margin: 0;
  font-size: 14px;
  text-align: center;
  color: ${({ theme }) => theme.color.primary.main};
  flex-shrink: 0;
  text-transform: lowercase;
`;
const MemberItem = ({
  id,
  type,
  photo,
  photoSize,
  name,
  memberType,
  relation,
  info,
  checked,
  onSelect,
  disabled,
  onDelete
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverUserId, setPopoverUserId] = useState(null);

  const popoverOpen = (event, userId) => {
    setAnchorEl(event.currentTarget);
    setPopoverUserId(userId);
  };

  const popoverClose = () => {
    setAnchorEl(null);
    setPopoverUserId(null);
  };

  return (
    <Container disabled={disabled}>
      <Box display="flex" alignItems="center">
        <RoundedAvatar
          imageSrc={type === "private" ? photo : null}
          imageAlt="Foto do membro"
          icon={type === "private" ? "person" : "group"}
          size={photoSize}
        />
        <MemberInfo>
          <Name
            memberType={memberType === "student"}
            title={name}
            onClick={e => {
              popoverOpen(e, id);
            }}
          >
            {name}
          </Name>
          {memberType && memberType === "student" && (
            <TypeMember title={memberType}>Aluno</TypeMember>
          )}
          <AdditionalInfo>{info}</AdditionalInfo>
          <Relation>{relation}</Relation>
        </MemberInfo>
      </Box>

      {popoverUserId && memberType === "student" && (
        <StudentInfoPopover
          anchorEl={anchorEl}
          onClose={popoverClose}
          userId={popoverUserId}
        />
      )}

      <Box display="flex" alignItems="center" flexShrink="0">
        {onSelect && (
          <Checkbox
            checked={checked}
            color="primary"
            onChange={onSelect}
            disabled={disabled}
          />
        )}

        {onDelete && (
          <IconButton color="primary" onClick={onDelete}>
            <CloseIcon />
          </IconButton>
        )}
      </Box>
    </Container>
  );
};

MemberItem.defaultProps = {
  id: null,
  photo: null,
  photoSize: 50,
  memberType: null,
  info: "",
  onSelect: null,
  onDelete: null,
  checked: false,
  disabled: false
};

MemberItem.propTypes = {
  id: PropTypes.number,
  type: PropTypes.oneOf(["private", "group"]).isRequired,
  photo: PropTypes.string,
  photoSize: PropTypes.number,
  name: PropTypes.string.isRequired,
  memberType: PropTypes.string,
  relation: PropTypes.string.isRequired,
  info: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func,
  onDelete: PropTypes.func
};

export default MemberItem;
