import React, { Component } from "react";
import PropTypes from "prop-types";
import styled, { css, withTheme } from "styled-components";
import {
  InfoOutlined as InfoIcon,
  ArrowBackIos as BackIcon,
  DoneAll as DoubleCheckIcon,
  ArrowBack,
  KeyboardArrowDown,
  Close as CloseIcon,
  Send as SendIcon,
  KeyboardArrowDown as ArrowDownIcon
} from "@material-ui/icons";
import {
  IconButton,
  Box,
  Slide,
  Checkbox,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid
} from "@material-ui/core";
import "emoji-mart/css/emoji-mart.css";
import _ from "lodash";
import fileSize from "filesize";
import { withAlert } from "react-alert";
import Grow from "@material-ui/core/Grow";
import Skeleton from "@material-ui/lab/Skeleton";
import moment from "@moment";
import Linkify from "linkify-react";
import ChatColumnHeader from "./ChatColumnHeader";
import ChatService from "../../../services/ChatService";
import RoundedAvatarWui from "../../../waybee-ui/RoundedAvatar";
import ScrollColumnBody from "./ScrollColumnBody";
import MessageField from "./MessageField";
import RocketChatWS from "../../../utils/RocketChatWS";
import OpenImage from "../../../waybee-ui/OpenImage";
import { AbilityContext, Can } from "../../../acl/can";
import Button from "../../../waybee-ui/Button";
import MemberItem from "./MemberItem";
import StudentInfoPopover from "../../../components/StudentInfoPopover";
import ClickPopover from "../../../components/ClickPopover";
import SimpleDialog from "../../../waybee-ui/Dialog/SimpleDialog";
import DialogButton from "../../../waybee-ui/Dialog/DialogButton";
import LoadingDialog from "../../../waybee-ui/Dialog/LoadingDialog";

const SlideContainer = styled(Slide)`
  @media (min-width: 784px) {
    transition: none !important;
  }
`;
const ChatInfos = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;
const ChatTitle = styled.p`
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  font-size: 20px;
  font-weight: 300;
  color: #000;
`;
const ChatTypeMember = styled.p`
  margin: 10px 0 0;
  font-size: 12px;
  color: ${({ theme }) => theme.color.primary.main};
`;
const StudentInfoContainer = styled.div`
  margin: 10px 0 5px;
`;
const StudentInfo = styled.p`
  margin: 1px 0;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
`;
const InfoButton = styled(Button)`
  display: flex;
  justify-content: end;
  margin-left: 0;
  padding-left: 0;
  font-size: 16px;
  p {
    margin: 0 0 0 9px;
    text-decoration: underline;
  }
`;
const MessagesContainer = styled.div`
  border-top: 2px solid ${({ theme }) => theme.color.background.default};
  display: flex;
  flex-direction: column-reverse;
`;
const MessageItem = styled.div`
  display: flex;
  margin: 10px 12px;

  &.self-message .content-container {
    flex-direction: row-reverse;
  }

  .checkbox-container {
    order: -1;
    flex-shrink: 0;
  }

  .content-container {
    display: flex;
    align-items: center;
    flex: 1;
  }
`;
const RoundedAvatar = styled(RoundedAvatarWui)`
  ${({ deleted }) =>
    deleted
      ? css`
          opacity: 0.5;
        `
      : ""};
`;
const MessageBubble = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  word-break: break-word;
  flex-direction: column;
  ${({ deleted }) =>
    deleted
      ? css`
          justify-content: center;
          opacity: 0.5;
        `
      : ""};
  ${({ file }) =>
    file
      ? css`
          white-space: nowrap;
          overflow: hidden;
        `
      : ""};
  padding: 8px 12px;
  background-color: ${({ theme }) => theme.color.primary.light};
  margin: 0 8px;
  border-radius: 8px;

  p {
    margin: 0;
  }

  &.self-message {
    background-color: ${({ theme }) => theme.color.background.default};
  }
`;
const BubbleTitle = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.color.primary.dark};
`;
const BubbleBody = styled.p`
  font-size: 14px;
  color: #666666;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  a {
    color: ${({ theme }) => theme.color.secondary.light};
    font-weight: bold;
  }
`;
const FileBody = styled.a`
  display: flex;
  margin: 4px 0;
  text-decoration: none;

  & * {
    white-space: nowrap;
    overflow: hidden;
  }
`;
const BubbleImage = styled.div`
  margin: 8px 0;
  text-align: center;

  img {
    max-width: 100%;
    max-height: 250px;
  }

  &:hover {
    cursor: pointer;
  }
`;
const FileInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 10px;
`;
const FileName = styled.div`
  font-size: 14px;
  color: #666666;
  display: flex;
  min-width: 0;

  p {
    margin: 0;
  }
`;
const FileNameBase = styled.p`
  text-overflow: ellipsis;
`;
const FileNameExtension = styled.p`
  flex-shrink: 0;
`;
const FileSize = styled.p`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
`;
const BubbleBottom = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const BubbleDate = styled.p`
  font-size: 12px;
  text-align: right;
  color: #999999;
`;
const BubbleRead = styled.p`
  && {
    margin: 0 0 0 8px;
  }
`;
const ContainerInput = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 2px solid ${({ theme }) => theme.color.background.default};
`;

const BackButton = styled(Button)`
  padding: 16px 22px;
  color: #787486;
`;
const ScrollToBottomButton = styled(IconButton)`
  position: absolute;
  bottom: ${({ bottomOffset }) => bottomOffset + 16}px;
  right: 16px;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

  &:hover {
    background-color: #f5f5f5;
  }
`;

const MessageActions = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
`;
const ForwardMessageBlock = styled.div`
  margin: 20px 12px;
  background-color: ${({ theme }) => theme.color.background.default};
  display: flex;
  align-items: center;
  overflow-y: auto;
  height: 100%;
  padding: 6px;
`;
const StyledCheckbox = styled(Checkbox)`
  &:hover {
    background-color: transparent;
  }
  &.Mui-checked:hover {
    background-color: transparent;
  }
`;

class MessagesColumn extends Component {
  constructor(props) {
    super(props);
    this.bottomRef = React.createRef();
    this.state = {
      openDetails: false,
      messageValue: "",
      loadingMessages: true,
      loadingSend: false,
      userId: localStorage.getItem("userId"),
      selectedImage: null,
      members: [],
      messages: [],
      showModalDeleteMessage: false,
      anchorEl: null,
      popoverUserId: null,
      bottomHeight: 0,
      forwardMessages: [],
      forwardActive: false,
      showChannelSelectDialog: false,
      selectedChannel: "",
      channels: [],
      isForwarding: false,
      showSearchBar: false,
      showScrollButton: false,
      searchResults: [],
      currentResult: 0
    };
  }

  componentDidMount() {
    this.setup();
    this.setBottomHeight("messages-bottom");
  }

  componentDidUpdate(prevProps, prevState) {
    const { chat } = this.props;
    const { openDetails } = this.state;

    if (chat !== prevProps.chat) this.reload(chat.rocketChatId);

    if (openDetails !== prevState.openDetails)
      this.setBottomHeight("messages-bottom");
  }

  componentWillUnmount() {
    RocketChatWS.sendUnsubRoom();
  }

  reload = async rocketChatId => {
    this.setState({ loadingMessages: true });
    await this.getMembers();
    RocketChatWS.sendLoadHistory(rocketChatId);
    RocketChatWS.sendUnsubRoom();
    RocketChatWS.sendSubRoom(rocketChatId);
  };

  setup = async () => {
    const { chat } = this.props;
    await this.getMembers();

    // Carrega Histórico de mensagens
    RocketChatWS.sendLoadHistory(chat.rocketChatId);
    RocketChatWS.addOnMessageFunc(event => {
      const message = JSON.parse(event.data);
      // Listen for loadHistory method result
      if (message.msg === "result" && message.result.messages) {
        const lastReadMessageId = this.getLastReadMessageId();
        const chatMessages = [];
        let read = false;
        message.result.messages.forEach(chatMessage => {
          const formattedMessage = this.formatMessage(chatMessage);
          if (formattedMessage) {
            if (lastReadMessageId === formattedMessage.id) read = true;
            formattedMessage.read = read;
            chatMessages.push(formattedMessage);
          }
        });
        this.setState(
          { messages: chatMessages, loadingMessages: false },
          () => {
            this.scrollToBottom();
            this.markAsRead();
          }
        );
      }
    });

    // Se inscreve para receber novas mensagens;
    RocketChatWS.sendSubRoom(chat.rocketChatId);
    RocketChatWS.addOnMessageFunc(event => {
      let { messages } = this.state;

      const message = JSON.parse(event.data);
      // Listen for room messages
      if (message.collection === "stream-room-messages" && message.fields) {
        const formattedMessage = this.formatMessage(message.fields.args[0]);
        if (
          formattedMessage &&
          !messages.some(msg => msg.id === formattedMessage.id)
        ) {
          messages.unshift(formattedMessage);
          this.setState({ messages }, this.scrollToBottom);
        }

        if (
          message.fields.args[0].t &&
          message.fields.args[0].t === "rm" &&
          messages.some(msg => msg.id === formattedMessage.id)
        ) {
          messages = messages.map(msg => {
            const newMsg = msg;

            if (newMsg.id === formattedMessage.id) newMsg.deleted = true;

            return newMsg;
          });

          this.setState({ messages });
        }
      }
    });
  };

  markAsRead = async () => {
    const { chat } = this.props;
    const { messages } = this.state;

    await ChatService.markAsRead(chat.id, messages[0].id);
    const refreshMessageStatus = new CustomEvent("refresh-chat-item", {
      detail: { unread: false }
    });

    document.dispatchEvent(refreshMessageStatus);
  };

  getLastReadMessageId = () => {
    const { members } = this.state;

    const readMessages = members
      .filter(member => {
        return (
          member.type === "family_member" &&
          member.info &&
          member.info.lastReadMessageId
        );
      })
      .map(member => {
        const formattedInfo = member.info;
        formattedInfo.updatedAt = new Date(member.info.updated_at);
        return formattedInfo;
      });
    const sortedInfos = _.sortBy(readMessages, "updatedAt");
    return sortedInfos.length
      ? sortedInfos[sortedInfos.length - 1].lastReadMessageId
      : null;
  };

  formatMessage = chatMessage => {
    const { members } = this.state;

    if (!chatMessage.u) return null;

    // eslint-disable-next-line no-underscore-dangle
    const foundMember = members.find(
      // eslint-disable-next-line no-underscore-dangle
      member => member.rocketChatId === chatMessage.u._id
    );
    if (!foundMember) return null;

    const msg = {
      // eslint-disable-next-line no-underscore-dangle
      id: chatMessage._id,
      date: moment(chatMessage.ts.$date).format("DD/MM/YYYY HH:mm"),
      body: chatMessage.msg,
      sender: foundMember,
      deleted: !!chatMessage.t
    };

    if (chatMessage.attachments && chatMessage.attachments.length) {
      if (chatMessage.file.type.includes("image")) {
        msg.type = "image";
      } else {
        msg.type = "file";
      }
    } else {
      msg.type = "text";
    }

    if (msg.type === "file" || msg.type === "image") {
      const nameParts = chatMessage.file.name.split(".");
      const extension = nameParts[nameParts.length - 1];

      const rocketInfo = JSON.parse(
        atob(localStorage.getItem("rocketChatInfo"))
      );
      const fileUrl = rocketInfo.url + chatMessage.attachments[0].title_link;

      const size = chatMessage.attachments[0].description;

      msg.file = {
        url: fileUrl,
        name: chatMessage.file.name.split(".")[0],
        extension,
        size: fileSize(Number.isNaN(Number(size)) ? 0 : size)
      };
    }

    return msg;
  };

  getMembers = async () => {
    try {
      const { chat } = this.props;
      const members = await ChatService.getChatMembers(chat.id);
      this.setState({ members }, this.getChannelFromMembers);
    } catch (e) {
      const { alert } = this.props;
      alert.show(`Erro ao buscar membros`, { title: "Erro" });
    }
  };

  getChannelFromMembers = async () => {
    const { chat } = this.props;
    const channels = await ChatService.getChannelsMember(chat.studentId);
    this.setState({ channels });
  };

  scrollToBottom = () => {
    const messagesBody = document.getElementById("messages-body");
    if (!messagesBody) return;
    messagesBody.scrollTop = messagesBody.scrollHeight;
  };

  deleteMessage = async msg => {
    const { alert, chat } = this.props;
    let { messages } = this.state;

    try {
      if (!msg.deleted) {
        await ChatService.deleteMessage(chat.id, msg.id);

        messages = messages.map(message => {
          const newMsg = message;
          if (newMsg.id === msg.id) newMsg.deleted = true;
          return newMsg;
        });

        this.setState({ /* anchorMenu: null, */ messages });

        const refreshEvent = new CustomEvent("refresh-chat-item", {
          detail: { deleted: true, chatId: chat.id }
        });
        document.dispatchEvent(refreshEvent);
      }
    } catch (e) {
      const error = _.get(e, "response.data.error", null);

      alert.show(error ? error.message : `Erro ao deletar mensagem.`, {
        title: "Erro"
      });
    }
  };

  showDeletedMessage = (msg, show) => {
    let { messages } = this.state;
    messages = messages.map(message => {
      const newMsg = message;
      if (newMsg.id === msg.id) newMsg.show = show;
      return newMsg;
    });

    this.setState({ messages });
  };

  sendMessage = async value => {
    this.setState({ loadingSend: true });
    try {
      const { chat } = this.props;
      await ChatService.sendMessage(chat.id, value);
      this.setState({ messageValue: "" });

      const refreshEvent = new CustomEvent("refresh-chat-item", {
        detail: { msg: value, chatId: chat.id }
      });
      document.dispatchEvent(refreshEvent);
    } catch (e) {
      const { alert } = this.props;
      alert.show(`Erro ao enviar mensagem`, { title: "Erro" });
    } finally {
      this.setState({ loadingSend: false });
    }
  };

  sendFile = async file => {
    this.setState({ loadingSend: true });
    try {
      const { chat } = this.props;
      const formData = new FormData();

      formData.append("file", file, file.name);
      formData.append("description", file.size);
      formData.append("msg", "Arquivo");

      await ChatService.sendFile(chat.id, formData);

      const refreshEvent = new CustomEvent("refresh-chat-item", {
        detail: { msg: "Arquivo", chatId: chat.id }
      });
      document.dispatchEvent(refreshEvent);
    } catch (e) {
      const { alert } = this.props;
      const msg = _.get(
        e,
        "response.data.error.message",
        "Erro ao enviar arquivo"
      );
      alert.show(msg, { title: "Erro" });
    } finally {
      this.setState({ loadingSend: false });
    }
  };

  showDeleteModal = async () => {
    const { showModalDeleteMessage } = this.state;

    this.setState({
      showModalDeleteMessage: !showModalDeleteMessage
    });
  };

  setBottomHeight = elementId => {
    const element = document.getElementById(elementId);
    this.setState({ bottomHeight: element ? element.offsetHeight : 0 });
  };

  formatDate = date => {
    const parsedDate = moment(date).parseZone();
    const minutes = moment().diff(moment(parsedDate), "minutes");
    const hours = moment().diff(moment(parsedDate), "hours");
    const days = moment()
      .endOf("day")
      .diff(moment(parsedDate).endOf("day"), "days");

    if (minutes <= 1) return `1 minuto atrás`;
    if (minutes <= 60) return `${minutes} minutos atrás`;
    if (hours <= 1) return `${hours} hora atrás`;
    if (hours <= 24) return `${hours} horas atrás`;
    if (days === 1)
      return moment
        .utc(parsedDate)
        .local()
        .format("[ontem às ] HH:mm");
    if (days > 1 && days <= 6) return `${days} dias atrás`;
    if (days > 6)
      return moment
        .utc(parsedDate)
        .local()
        .format("DD/MM/YY");

    return "";
  };

  getMessageActions = (message, userId) => {
    const { context } = this;

    const items = [];

    if (context.can("use module", "forwardMessage")) {
      items.push({
        title: "Encaminhar",
        onClick: () => this.setState({ forwardActive: true })
      });
    }

    if (userId === message.sender.id.toString() && !message.deleted) {
      items.push({
        title: "Apagar mensagem",
        onClick: data => this.deleteMessage(data)
      });
    }

    return items;
  };

  toggleForwardCheckbox = message => {
    const { selectedChannelName } = this.props;

    const forwardMessage = {
      ...message,
      forwardBody: `[Mensagem de ${message.sender.name} encaminhada de ${selectedChannelName}] ${message.body}`
    };

    this.setState(prevState => {
      const { forwardMessages } = prevState;
      const isMessageSelected = forwardMessages.some(
        msg => msg.id === forwardMessage.id
      );

      if (isMessageSelected) {
        return {
          forwardMessages: forwardMessages.filter(
            msg => msg.id !== forwardMessage.id
          )
        };
      }
      return {
        forwardMessages: [...forwardMessages, forwardMessage]
      };
    });
  };

  handleForward = () => {
    this.setState({ showChannelSelectDialog: true });
  };

  handleChannelSelect = event => {
    this.setState({ selectedChannel: event.target.value });
  };

  confirmForward = async () => {
    const { chat, alert } = this.props;
    const { forwardMessages, selectedChannel, members } = this.state;

    this.setState({
      isForwarding: true,
      showChannelSelectDialog: false,
      forwardActive: false
    });

    try {
      const destinationMembers = members
        .filter(member => member.type === "family_member")
        .map(member => ({
          id: member.id,
          type: "private",
          student: chat.studentId
        }));

      await ChatService.forwardMessages(
        selectedChannel.id,
        destinationMembers,
        forwardMessages
      );

      alert.show("Mensagens encaminhadas com sucesso!", {
        title: "Sucesso",
        type: "success"
      });
    } catch (e) {
      alert.show("Erro ao encaminhar mensagens. Por favor, tente novamente.", {
        title: "Erro",
        type: "error"
      });
    } finally {
      this.setState({
        isForwarding: false,
        forwardMessages: []
      });
    }
  };

  isScrolledToBottom = () => {
    const messagesBody = document.getElementById("messages-body");
    if (!messagesBody) return true;

    const threshold = 100;
    return (
      messagesBody.scrollHeight -
        messagesBody.scrollTop -
        messagesBody.clientHeight <
      threshold
    );
  };

  handleScroll = () => {
    const { showScrollButton } = this.state;
    const isBottom = this.isScrolledToBottom();
    if (showScrollButton === !isBottom) return;

    this.setState({ showScrollButton: !isBottom });
  };

  searchMessage = queryMessage => {
    if (!queryMessage) {
      this.setState({
        searchResults: [],
        currentResult: 0
      });
      return;
    }

    const { messages } = this.state;
    const results = [];

    messages.forEach((msg, index) => {
      if (
        msg.type === "text" &&
        msg.body &&
        msg.body.toLowerCase().includes(queryMessage.toLowerCase())
      ) {
        results.push({
          messageId: msg.id,
          messageIndex: index,
          text: msg.body
        });
      }
    });

    this.setState(
      {
        searchResults: results,
        currentResult: 0
      },
      () => {
        if (results.length > 0) {
          this.scrollToMessageResult(0);
        }
      }
    );
  };

  scrollToMessageResult = resultIndex => {
    const { searchResults } = this.state;
    const { theme } = this.props;
    const result = searchResults[resultIndex];
    if (!result) return;

    const messageElement = document.getElementById(`msg-${result.messageId}`);
    if (messageElement) {
      messageElement.scrollIntoView({ behavior: "smooth", block: "center" });
      messageElement.style.transition = "background-color 0.5s";
      messageElement.style.backgroundColor = theme.color.primary.main;
      setTimeout(() => {
        messageElement.style.backgroundColor = "";
      }, 1000);
    }
  };

  handleNextMessageResult = () => {
    const { currentResult, searchResults } = this.state;
    const nextResult =
      currentResult < searchResults.length - 1 ? currentResult + 1 : 0;

    this.setState({ currentResult: nextResult }, () => {
      this.scrollToMessageResult(nextResult);
    });
  };

  handlePrevMessageResult = () => {
    const { currentResult, searchResults } = this.state;
    const prevResult =
      currentResult > 0 ? currentResult - 1 : searchResults.length - 1;

    this.setState({ currentResult: prevResult }, () => {
      this.scrollToMessageResult(prevResult);
    });
  };

  render() {
    const { chat, currentSection, onClose } = this.props;

    const {
      openDetails,
      messageValue,
      loadingMessages,
      loadingSend,
      selectedImage,
      userId,
      messages,
      members,
      bottomHeight,
      anchorEl,
      forwardMessages,
      forwardActive,
      showChannelSelectDialog,
      selectedChannel,
      channels,
      isForwarding,
      popoverUserId,
      showSearchBar,
      showScrollButton,
      searchResults,
      currentResult
    } = this.state;

    const isMobile = window.innerWidth <= 784;
    const isCurrentSection = isMobile ? currentSection === "messages" : true;
    return (
      <SlideContainer
        direction="right"
        in={isCurrentSection}
        mountOnEnter
        unmountOnExit
      >
        <Box
          height="100%"
          position="relative"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Box id="messages-header">
            {isMobile && (
              <BackButton onClick={onClose} startIcon={<ArrowBack />}>
                Voltar
              </BackButton>
            )}
            <ChatColumnHeader
              onClickIcon={() =>
                this.setState({ showSearchBar: !showSearchBar })
              }
              searchBar={showSearchBar}
              icon="search"
              title="Conversa com"
              onSearch={this.searchMessage}
              searchResults={searchResults}
              currentResult={currentResult}
              onNextResult={this.handleNextMessageResult}
              onPrevResult={this.handlePrevMessageResult}
              onClearSearch={() => this.searchMessage("")}
            >
              <Box mt={1.6} display="flex">
                <RoundedAvatar
                  imageSrc={chat.type === "private" ? chat.photo : null}
                  imageAlt="Foto do chat"
                  icon={chat.type === "private" ? "person" : "group"}
                  size={86}
                />
                <ChatInfos>
                  <ChatTitle>{chat.title}</ChatTitle>
                  {chat.type === "private" && chat.typeMember && (
                    <ChatTypeMember>{chat.typeMember}</ChatTypeMember>
                  )}
                  <StudentInfoContainer
                    onClick={event => {
                      this.setState({
                        anchorEl: event.currentTarget,
                        popoverUserId: chat.studentId
                      });
                    }}
                  >
                    <StudentInfo>
                      Aluno:
                      {` ${chat.studentName}`}
                      {" - "}
                      {chat.studentInfos}
                    </StudentInfo>
                    {chat.studentGrade && (
                      <StudentInfo>{chat.studentGrade}</StudentInfo>
                    )}
                    {chat.studentGradeClass && (
                      <StudentInfo>{chat.studentGradeClass}</StudentInfo>
                    )}
                  </StudentInfoContainer>
                  {popoverUserId && (
                    <StudentInfoPopover
                      anchorEl={anchorEl}
                      onClose={() => {
                        this.setState({
                          anchorEl: null,
                          popoverUserId: null
                        });
                      }}
                      userId={popoverUserId}
                    />
                  )}
                  {openDetails ? (
                    <InfoButton
                      color="primary"
                      onClick={() =>
                        this.setState(
                          { openDetails: false },
                          this.scrollToBottom
                        )
                      }
                    >
                      <BackIcon />
                      <p>Voltar à conversa</p>
                    </InfoButton>
                  ) : (
                    <InfoButton
                      color="primary"
                      onClick={() => this.setState({ openDetails: true })}
                    >
                      <InfoIcon />
                      <p>Ver informações da conversa</p>
                    </InfoButton>
                  )}
                </ChatInfos>
              </Box>
            </ChatColumnHeader>
          </Box>

          {!openDetails ? (
            <>
              <ScrollColumnBody
                bodyId="messages-body"
                subtractIds={["messages-header", "messages-bottom"]}
                mb={bottomHeight}
                onScroll={this.handleScroll}
              >
                <MessagesContainer>
                  {loadingMessages ? (
                    Array.from(new Array(5)).map(() => (
                      <Box display="flex" p={1.2}>
                        <Skeleton variant="circle" width={44} height={44} />
                        <Box
                          flexGrow={1}
                          ml={1}
                          display="flex"
                          justifyContent="center"
                          flexDirection="column"
                        >
                          <Skeleton variant="text" width="100%" height={10} />
                          <Skeleton variant="text" width="100%" height={10} />
                          <Skeleton variant="text" width="100%" height={10} />
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <>
                      {messages.map(msg => {
                        const messageActions = this.getMessageActions(
                          msg,
                          userId
                        );

                        return (
                          <MessageItem
                            key={msg.id}
                            className={
                              userId === msg.sender.id.toString()
                                ? "self-message"
                                : ""
                            }
                            id={`msg-${msg.id}`}
                          >
                            {forwardActive && (
                              <StyledCheckbox
                                style={{ marginRight: "3px" }}
                                color="primary"
                                checked={forwardMessages.some(
                                  fwdMsg => fwdMsg.id === msg.id
                                )}
                                onChange={() => this.toggleForwardCheckbox(msg)}
                              />
                            )}
                            <div className="content-container">
                              <Grow in>
                                <RoundedAvatar
                                  deleted={msg.deleted && !msg.show}
                                  imageSrc={msg.sender.photo}
                                  imageAlt="Foto do usuário que enviou mensagem"
                                  icon="person"
                                  size={44}
                                />
                              </Grow>
                              <Grow in>
                                <MessageBubble
                                  file={msg.type === "file"}
                                  deleted={msg.deleted && !msg.show}
                                  className={
                                    userId === msg.sender.id.toString()
                                      ? "self-message"
                                      : ""
                                  }
                                  style={{
                                    flex: 1,
                                    transition: "flex 0.3s ease-in-out"
                                  }}
                                >
                                  {messageActions.length > 0 && (
                                    <MessageActions>
                                      <IconButton size="small">
                                        <ClickPopover
                                          items={messageActions}
                                          data={msg}
                                        >
                                          <KeyboardArrowDown
                                            style={{
                                              color: "rgba(0, 0, 0, 0.3)"
                                            }}
                                          />
                                        </ClickPopover>
                                      </IconButton>
                                    </MessageActions>
                                  )}
                                  {msg.deleted && !msg.show ? (
                                    <BubbleBody>
                                      Essa mensagem foi apagada.
                                    </BubbleBody>
                                  ) : (
                                    <>
                                      <BubbleTitle>
                                        {msg.sender.name}
                                      </BubbleTitle>
                                      {msg.type === "file" && (
                                        <FileBody
                                          href={msg.file.url}
                                          target="_blank"
                                          download
                                        >
                                          <RoundedAvatar
                                            size={42}
                                            icon="description"
                                          />
                                          <FileInfo>
                                            <FileName>
                                              <FileNameBase>
                                                {msg.file.name}
                                              </FileNameBase>
                                              <FileNameExtension>
                                                .
                                                {msg.file.extension}
                                              </FileNameExtension>
                                            </FileName>
                                            <FileSize>{msg.file.size}</FileSize>
                                          </FileInfo>
                                        </FileBody>
                                      )}
                                      {msg.type === "text" && (
                                        <BubbleBody>
                                          <Linkify
                                            options={{
                                              target: "_blank",
                                              rel: "noopener noreferrer"
                                            }}
                                          >
                                            {msg.body}
                                          </Linkify>
                                        </BubbleBody>
                                      )}
                                      {msg.type === "image" && (
                                        <BubbleImage
                                          onClick={() =>
                                            this.setState({
                                              selectedImage: msg.file.url
                                            })
                                          }
                                        >
                                          <img
                                            src={msg.file.url}
                                            onLoad={this.scrollToBottom}
                                            alt="Imagem enviada"
                                          />
                                        </BubbleImage>
                                      )}
                                      <BubbleBottom>
                                        <BubbleDate>{msg.date}</BubbleDate>
                                        {userId ===
                                          msg.sender.id.toString() && (
                                          <BubbleRead>
                                            {msg.read ? (
                                              <DoubleCheckIcon
                                                color="primary"
                                                fontSize="small"
                                              />
                                            ) : (
                                              <DoubleCheckIcon fontSize="small" />
                                            )}
                                          </BubbleRead>
                                        )}
                                      </BubbleBottom>
                                    </>
                                  )}
                                </MessageBubble>
                              </Grow>
                            </div>
                          </MessageItem>
                        );
                      })}
                    </>
                  )}
                </MessagesContainer>
                {showScrollButton && (
                  <ScrollToBottomButton
                    bottomOffset={bottomHeight}
                    onClick={this.scrollToBottom}
                    size="medium"
                  >
                    <ArrowDownIcon />
                  </ScrollToBottomButton>
                )}
              </ScrollColumnBody>
              <Can I="send" a="message">
                <ContainerInput id="messages-bottom" ref={this.bottomRef}>
                  {forwardActive ? (
                    <ForwardMessageBlock>
                      <IconButton
                        onClick={() =>
                          this.setState({
                            forwardActive: false,
                            forwardMessages: []
                          })
                        }
                      >
                        <CloseIcon />
                      </IconButton>
                      <Typography
                        variant="body1"
                        style={{ flexGrow: 1, margin: 0 }}
                      >
                        {`${forwardMessages.length} mensage${
                          forwardMessages.length !== 1 ? "ns" : "m"
                        } selecionada${
                          forwardMessages.length !== 1 ? "s" : ""
                        }`}
                      </Typography>
                      <IconButton onClick={this.handleForward}>
                        <SendIcon />
                      </IconButton>
                    </ForwardMessageBlock>
                  ) : (
                    <MessageField
                      value={messageValue}
                      onChange={value => this.setState({ messageValue: value })}
                      onSend={this.sendMessage}
                      onSendFile={this.sendFile}
                      loading={loadingSend}
                    />
                  )}
                </ContainerInput>
              </Can>
            </>
          ) : (
            <ScrollColumnBody
              bodyId="messages-info"
              subtractIds={["messages-header"]}
            >
              {members.map(member => {
                if (member.type === "communication") return null;

                const lastAccess = _.get(member, "info.lastAccess", null);

                return (
                  <MemberItem
                    key={member.id}
                    relation={
                      member.type === "employee"
                        ? "Funcionário"
                        : member.relation
                    }
                    name={member.name}
                    photo={member.photo}
                    photoSize={66}
                    memberType={
                      member.type === "employee"
                        ? "Funcionário"
                        : member.categories.join("\n")
                    }
                    info={
                      lastAccess &&
                      `visto por último ${this.formatDate(lastAccess)}`
                    }
                    type="private"
                  />
                );
              })}
            </ScrollColumnBody>
          )}

          {selectedImage && (
            <OpenImage
              src={selectedImage}
              onClose={() => this.setState({ selectedImage: null })}
            />
          )}

          {showChannelSelectDialog && (
            <SimpleDialog
              open={showChannelSelectDialog}
              onClose={() => this.setState({ showChannelSelectDialog: false })}
              title="Encaminhar mensagens"
              message="Selecione o canal para encaminhar as mensagens"
              actions={(
                <Grid container spacing={2} style={{ padding: "0 14px" }}>
                  <Grid item xs={12} style={{ padding: "0 14px 36px 14px" }}>
                    <FormControl size="small" fullWidth>
                      <InputLabel>Canal</InputLabel>
                      <Select
                        value={selectedChannel}
                        onChange={this.handleChannelSelect}
                      >
                        {channels.map(channel => (
                          <MenuItem key={channel.id} value={channel}>
                            {channel.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <DialogButton
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        this.setState({
                          showChannelSelectDialog: false,
                          forwardActive: false
                        })
                      }
                    >
                      Cancelar
                    </DialogButton>
                  </Grid>
                  <Grid item xs={6}>
                    <DialogButton
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={this.confirmForward}
                      disabled={!selectedChannel}
                    >
                      Confirmar
                    </DialogButton>
                  </Grid>
                </Grid>
              )}
            />
          )}

          <LoadingDialog
            open={isForwarding}
            onClose={() => this.setState({ isForwarding: false })}
          />
        </Box>
      </SlideContainer>
    );
  }
}

MessagesColumn.contextType = AbilityContext;

MessagesColumn.propTypes = {
  chat: PropTypes.shape({
    id: PropTypes.string,
    rocketChatId: PropTypes.string,
    type: PropTypes.string,
    photo: PropTypes.string,
    title: PropTypes.string,
    studentId: PropTypes.number,
    studentName: PropTypes.string,
    studentInfos: PropTypes.string,
    typeMember: PropTypes.string,
    studentGrade: PropTypes.string,
    studentGradeClass: PropTypes.string
  }).isRequired,
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired,
  currentSection: PropTypes.string.isRequired,
  selectedChannelName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired
};

export default withTheme(withAlert()(MessagesColumn));
