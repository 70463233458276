import React, { useState, useEffect } from "react";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { Button, Grid, Icon, withTheme } from "@material-ui/core";
import styled from "styled-components";
import { ClearOutlined } from "@material-ui/icons";
import PropTypes from "prop-types";
import LinksDialog from "./LinksDialog";

const DataGrid = styled(ReactDataGrid)`
  &.InovuaReactDataGrid {
    border: none;
    font-family: Nunito, sans-serif;
    font-size: 14px;
    min-height: 550px;
    cursor: pointer;
  }
  .InovuaReactDataGrid__column-header {
    border: none;
  }
`;

const NewLinkButton = styled(Button)`
  margin: 20px 0;
`;

const TableContainer = styled(Grid)`
  padding: 20px 16px;
`;

const ExternalLinks = ({ links, onRemoveLink, onNewLink, theme }) => {
  const [dataSource, setDataSource] = useState(links);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(false);

  useEffect(() => {
    setDataSource(links);
  }, [links]);

  const onDelete = (e, rowData) => {
    e.stopPropagation();
    const newDataSource = [...dataSource];
    newDataSource.splice(rowData, 1);

    setDataSource(newDataSource);
    onRemoveLink(newDataSource);
  };

  const onSave = values => {
    const data = [...dataSource];

    if (selectedRow) {
      const foundLinkIndex = data.findIndex(link => link.id === selectedRow.id);
      data.splice(foundLinkIndex, 1);
    }

    const newLink = {
      id: data.length,
      name: values.name,
      link: values.link,
      icon: values.icon,
      public: values.public,
      inAppLink: values.inAppLink,
      destinationStudent: values.destinationStudent,
      destinationFinantial: values.destinationFinantial,
      destinationPedagogical: values.destinationPedagogical,
      menu: values.menu
    };

    data.push(newLink);
    setDataSource(data);
    onNewLink(data);
    setOpenDialog(false);
  };

  const columns = [
    {
      name: "icon",
      header: "Ícone",
      defaultFlex: 1,
      maxWidth: 150,
      resizable: false,
      render: ({ value }) => {
        return <Icon style={{ fontSize: 32 }}>{value}</Icon>;
      }
    },
    {
      name: "name",
      header: "Título do Botão",
      minWidth: 50,
      defaultFlex: 2,
      resizable: false
    },
    {
      name: "link",
      header: "Link",
      maxWidth: 1000,
      defaultFlex: 1,
      resizable: false
    },
    {
      name: "action",
      header: "Ações",
      maxWidth: 100,
      defaultFlex: 1,
      editable: false,
      resizable: false,
      render: rowData => {
        const rowId = rowData.rowIndex;
        return (
          <Button
            id={rowData.rowIndex}
            variant="outlined"
            style={{ borderColor: theme.palette.error.main }}
            onClick={e => onDelete(e, rowId)}
          >
            <ClearOutlined color="error" />
          </Button>
        );
      }
    }
  ];

  return (
    <TableContainer>
      <NewLinkButton
        variant="outlined"
        onClick={() => {
          setSelectedRow(null);
          setOpenDialog(true);
        }}
      >
        Adicionar Link
      </NewLinkButton>
      <DataGrid
        showCellBorders={false}
        showZebraRows={false}
        resizable={false}
        reorderColumns={false}
        cellSelectionByIndex={false}
        sortable={false}
        idProperty="id"
        columns={columns}
        dataSource={dataSource}
        style={{ minHeight: 500 }}
        showColumnMenuTool={false}
        onSelectionChange={row => {
          setSelectedRow(row.data);
          setOpenDialog(true);
        }}
        enableSelection
      />
      <LinksDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onSave={onSave}
        values={selectedRow}
      />
    </TableContainer>
  );
};

ExternalLinks.propTypes = {
  theme: PropTypes.objectOf().isRequired,
  onRemoveLink: PropTypes.func.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onNewLink: PropTypes.func.isRequired
};

export default withTheme(ExternalLinks);
