import React, { Component } from "react";
import { BrowserRouter, Link, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  AlternateEmail as AlternateEmailIcon,
  Assessment as GraphIcon,
  BorderColor as ActivityIcon,
  Chat as ChatIcon,
  HeadsetMic as SupportIcon,
  Event as CalendarIcon,
  ExitToApp as ExitToAppIcon,
  Home as HomeIcon,
  Mail as MailIcon,
  School as SchoolIcon,
  Settings,
  People as UsersIcon
} from "@material-ui/icons";
import { Box, CircularProgress } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import OneSignal from "react-onesignal";
import DOMPurify from "dompurify";
import Hotjar from "@hotjar/browser";
import Toolbar from "./waybee-ui/Toolbar";
import ListItem from "./waybee-ui/List/ListItem";
import Alert from "./components/Alert";
import UserService from "./services/UserService";
import UserStore from "./utils/UserStore";
import Login from "./views/Login/Login";
import Dashboard from "./views/Dashboard/Dashboard";
import EmailDashboard from "./views/EmailDashboard/EmailDashboard";
import CommunicationForm from "./views/CommunicationForm/CommunicationForm";
import EmailForm from "./views/EmailForm/EmailForm";
import CommunicationDetails from "./views/CommunicationDetails/CommunicationDetails";
import EmailDetails from "./views/EmailDetails/EmailDetails";
import ActivitiesDashboard from "./views/ActivitiesDashboard";
import ActivityForm from "./views/ActivityForm/ActivityForm";
import ActivityDetails from "./views/ActivityDetails/ActivityDetails";
import fallbackAvatarImage from "./assets/images/avatar-demo.png";
import Chat from "./views/Chat/Chat";
import { AbilityContext, Can } from "./acl/can";
import abilityPromisse from "./acl/ability";
import { setCampuses } from "./redux/actions";
import SelectCampus from "./components/SelectCampus";
import { normalizeUserCampuses } from "./utils/normalizers";
import AccessionPanel from "./views/AccessionPanel";
import AccessionUsers from "./views/AccessionUsers";
import AccessionActions from "./views/AccessionActions";
import Acl from "./views/Acl/Acl";
import Calendar from "./views/Calendar";
import CalendarForm from "./views/CalendarForm";
import CalendarEventForm from "./views/CalendarEventForm";
import AccessionStudentActions from "./views/AccessionStudentActions";
import AppService from "./services/AppService";
import PaletteView from "./views/Palette/PaletteView";
import Configuration from "./views/Configuration/Configuration";
import AclUser from "./views/AclUser/AclUser";
import TicketChannels from "./views/TicketChannels";
import Tickets from "./views/Tickets";
import TicketChannelForm from "./views/TicketChannelForm/TicketChannelForm";
import Users from "./views/Users";
import AclList from "./views/AclList";
import Groups from "./views/Groups/Groups";

const siteId = 5096559;
const hotjarVersion = 6;

const hasTokenLogin = process.env.REACT_APP_TOKEN_LOGIN === "true";

DOMPurify.addHook("uponSanitizeElement", (node, data) => {
  if (data.tagName === "iframe") {
    const src = node.getAttribute("src") || "";
    if (!src.startsWith("https://www.youtube.com/embed/")) {
      return node.parentNode.removeChild(node);
    }
    return node;
  }
  return node;
});

const AppVersion = styled.span`
  font-size: 16px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.5);
  margin-left: 12px;
  margin-top: 4px;
  display: block;
`;

const List = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: calc(100% - 90px);
`;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: null,
      activeTitle: "",
      alertOptions: {
        open: false,
        status: "",
        title: "",
        message: ""
      },
      userName: "",
      userPhoto: "",
      ability: null,
      selectedCampus: null
    };
  }

  componentDidMount = () => {
    if (hasTokenLogin) {
      this.tokenAuthenticate();
    } else {
      this.loadApp();
    }
  };

  loadAbility = () => {
    abilityPromisse().then(data => {
      this.setState({ ability: data });
    });
  };

  updateInstallation = async () => {
    await OneSignal.showSlidedownPrompt();
    const subscription = await OneSignal.isPushNotificationsEnabled();
    if (subscription) {
      await AppService.updateInstallationUser();
    }
  };

  setTitle = title => {
    this.setState({ activeTitle: title });
  };

  openAlert = ({ status, title, message, loading, onClose = () => {} }) => {
    this.setState({
      alertOptions: {
        open: true,
        status,
        title,
        message,
        loading,
        onClose
      }
    });
  };

  onCloseAlert = () => {
    this.setState({ alertOptions: { open: false } });
  };

  handleLogout = async () => {
    try {
      if (localStorage.getItem("oneSignalAppId")) {
        const subscribed = await OneSignal.isPushNotificationsEnabled();
        if (subscribed) {
          const playerId = await OneSignal.getUserId();
          await AppService.disableInstallationUser(playerId);
        }
      }
    } catch (e) {
      console.error(e);
    }

    await UserService.logout();
    this.setState({ isAuthenticated: false });
  };

  loadApp = () => {
    const userName = UserStore.getUserName();
    const userPhoto = UserStore.getUserPhoto();
    const isAuthenticated = UserService.isAuthenticated();
    this.setState({ userName, userPhoto, isAuthenticated });

    if (isAuthenticated) {
      this.loadAbility();
      this.getUserCampuses();
      this.updateInstallation();
    }

    window.addEventListener("storage", e => {
      const keysToWatch = ["token", "userSchoolId", "userCampusId"];
      if (keysToWatch.includes(e.key) && e.oldValue !== e.newValue) {
        window.location = window.location.origin;
      }
    });

    Hotjar.init(siteId, hotjarVersion);
  };

  automaticSelectedCampus = campus => {
    let selectedCampus = UserStore.getUserCampusId();
    if (!selectedCampus) selectedCampus = campus[0] && campus[0].id;

    this.setState({ selectedCampus });
  };

  getUserCampuses = () => {
    const { campuses, setCampuses: setUserCampuses } = this.props;
    const userId = UserStore.getUserId();

    if (!campuses && userId) {
      UserService.getSchoolUser(userId).then(data => {
        const normalizedUserCampuses = normalizeUserCampuses(data);
        setUserCampuses(normalizedUserCampuses);

        this.automaticSelectedCampus(normalizedUserCampuses);
      });
    } else if (campuses && userId) {
      this.automaticSelectedCampus(campuses);
    }
  };

  onChangeCampus = event => {
    const { campuses } = this.props;

    const selectedCampus = campuses.find(
      campus => campus.id === event.target.value
    );

    if (selectedCampus) {
      this.setState({ selectedCampus: selectedCampus.id }, () => {
        UserStore.setUserCampusId(selectedCampus.id);
        UserStore.setUserSchoolId(selectedCampus.school.id);
        window.location.reload();
      });
    }
  };

  tokenAuthenticate = async () => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const token = params.get("authToken");

    if (token) {
      if (UserService.isAuthenticated()) await this.handleLogout();

      try {
        const userId = await UserService.login({ token });
        const user = await UserService.getUserData(userId);
        await UserService.setUserData(user);
        window.location.search = "";
      } catch (e) {
        console.error(e);
      }
    }

    this.loadApp();
  };

  render() {
    const {
      isAuthenticated,
      activeTitle,
      alertOptions,
      userName,
      userPhoto,
      ability,
      selectedCampus
    } = this.state;

    const { campuses, t } = this.props;

    if (isAuthenticated === null) {
      return (
        <Box py={20} textAlign="center">
          <CircularProgress />
        </Box>
      );
    }

    if (!isAuthenticated) return <Login loadApp={this.loadApp} />;

    if (ability)
      return (
        <AbilityContext.Provider value={ability}>
          <BrowserRouter>
            <Toolbar
              activeTitle={activeTitle}
              userPhoto={userPhoto || fallbackAvatarImage}
              userName={userName}
              listMenu={(
                <List>
                  <div>
                    <ListItem icon={<SchoolIcon />} button={false}>
                      <SelectCampus
                        campuses={campuses}
                        selected={selectedCampus}
                        onChangeCampus={this.onChangeCampus}
                      />
                    </ListItem>

                    {ability.can("use module", "accession") ? (
                      <ListItem icon={<GraphIcon />} component={Link} to="/">
                        Painel de Adesão
                      </ListItem>
                    ) : (
                      <ListItem icon={<HomeIcon />} component={Link} to="/">
                        Home
                      </ListItem>
                    )}

                    <Can I="use module" a="communication">
                      <ListItem
                        icon={<MailIcon />}
                        component={Link}
                        to="/communication"
                      >
                        {t("menu.communications", "Comunicações")}
                      </ListItem>
                    </Can>

                    <Can I="use module" a="campaign">
                      <ListItem
                        icon={<AlternateEmailIcon />}
                        component={Link}
                        to="/email"
                      >
                        {t("menu.email", "E-mail")}
                      </ListItem>
                    </Can>

                    <Can I="use module" a="activity">
                      <ListItem
                        icon={<ActivityIcon />}
                        component={Link}
                        to="/activities"
                      >
                        {t("menu.activities", "Atividades")}
                      </ListItem>
                    </Can>

                    <Can I="use module" a="message">
                      <ListItem icon={<ChatIcon />} component={Link} to="/chat">
                        {t("menu.messages", "Mensagens")}
                      </ListItem>
                    </Can>

                    <Can I="use module" a="calendar">
                      <ListItem
                        icon={<CalendarIcon />}
                        component={Link}
                        to="/calendar"
                      >
                        Calendário Geral
                      </ListItem>
                    </Can>

                    <Can I="use module" a="ticketsCommunication">
                      <ListItem
                        icon={<SupportIcon />}
                        component={Link}
                        to="/ticket-channels"
                      >
                        {t("menu.ticketChannels", "Atendimentos")}
                      </ListItem>
                    </Can>

                    <Can I="use module" a="communicationUsers">
                      <ListItem
                        icon={<UsersIcon />}
                        component={Link}
                        to="/users"
                      >
                        {t("menu.users", "Usuários")}
                      </ListItem>
                    </Can>
                  </div>
                  <div>
                    <AppVersion>{process.env.REACT_APP_VERSION}</AppVersion>
                    <Can I="use module" a="commConfiguration">
                      <ListItem
                        icon={<Settings />}
                        component={Link}
                        to="/configuration"
                      >
                        Configurações
                      </ListItem>
                    </Can>
                    <ListItem
                      component={Link}
                      to="/"
                      icon={<ExitToAppIcon />}
                      button
                      onClick={this.handleLogout}
                    >
                      {t("menu.logout", "Sair")}
                    </ListItem>
                  </div>
                </List>
              )}
            >
              <Switch>
                <>
                  <Can I="use module" a="communication">
                    <Route
                      exact
                      path="/communication"
                      render={props => (
                        <Dashboard
                          setTitle={this.setTitle}
                          openAlert={this.openAlert}
                          {...props}
                        />
                      )}
                    />
                    <Can I="create" a="communication">
                      <Route
                        exact
                        path="/form"
                        render={props => (
                          <CommunicationForm
                            setTitle={this.setTitle}
                            openAlert={this.openAlert}
                            {...props}
                          />
                        )}
                      />
                    </Can>
                    <Can I="view" a="communication">
                      <Route
                        exact
                        path="/communication/:id"
                        render={props => (
                          <CommunicationDetails
                            setTitle={this.setTitle}
                            openAlert={this.openAlert}
                            {...props}
                          />
                        )}
                      />
                    </Can>
                    <Can I="edit" a="communication">
                      <Route
                        exact
                        path="/communication/:id/edit"
                        render={props => (
                          <CommunicationForm
                            setTitle={this.setTitle}
                            openAlert={this.openAlert}
                            {...props}
                          />
                        )}
                      />
                    </Can>
                  </Can>
                  <Can I="use module" a="message">
                    <Route
                      exact
                      path="/chat"
                      render={props => (
                        <Chat
                          setTitle={this.setTitle}
                          openAlert={this.openAlert}
                          {...props}
                        />
                      )}
                    />
                  </Can>
                  <Can I="use module" a="campaign">
                    <Route
                      exact
                      path="/email"
                      render={() => <EmailDashboard setTitle={this.setTitle} />}
                    />
                    <Can I="view" a="campaign">
                      <Route
                        exact
                        path="/email/:id"
                        render={props => (
                          <EmailDetails
                            setTitle={this.setTitle}
                            openAlert={this.openAlert}
                            {...props}
                          />
                        )}
                      />
                    </Can>
                    <Can I="create" a="campaign">
                      <Route
                        exact
                        path="/create-email"
                        render={props => (
                          <EmailForm
                            setTitle={this.setTitle}
                            openAlert={this.openAlert}
                            {...props}
                          />
                        )}
                      />
                    </Can>
                  </Can>

                  <Can I="use module" a="activity">
                    <Route
                      exact
                      path="/activities"
                      render={() => (
                        <ActivitiesDashboard
                          setTitle={this.setTitle}
                          openAlert={this.openAlert}
                        />
                      )}
                    />
                    <Can I="view" a="activity">
                      <Route
                        exact
                        path="/activity/:id"
                        render={props => (
                          <ActivityDetails
                            setTitle={this.setTitle}
                            openAlert={this.openAlert}
                            {...props}
                          />
                        )}
                      />
                    </Can>
                    <Can I="create" a="activity">
                      <Route
                        exact
                        path="/activitY"
                        render={props => (
                          <ActivityForm
                            setTitle={this.setTitle}
                            openAlert={this.openAlert}
                            {...props}
                          />
                        )}
                      />
                    </Can>
                    <Can I="edit" a="activity">
                      <Route
                        exact
                        path="/activitY/:id/edit"
                        render={props => (
                          <ActivityForm
                            setTitle={this.setTitle}
                            openAlert={this.openAlert}
                            {...props}
                          />
                        )}
                      />
                    </Can>
                  </Can>
                  <Route
                    exact
                    path="/"
                    render={props => (
                      <AccessionPanel setTitle={this.setTitle} {...props} />
                    )}
                  />

                  <Can I="use module" a="accession">
                    <Route
                      exact
                      path="/accession-panel/:type"
                      render={props => (
                        <AccessionUsers setTitle={this.setTitle} {...props} />
                      )}
                    />

                    <Can I="edit" a="accession">
                      <Route
                        exact
                        path="/accession-panel/responsibles/:userId"
                        render={props => (
                          <AccessionActions
                            setTitle={this.setTitle}
                            {...props}
                          />
                        )}
                      />
                    </Can>

                    <Can I="edit" a="accession">
                      <Route
                        exact
                        path="/accession-panel/students/:userId"
                        render={props => (
                          <AccessionStudentActions
                            setTitle={this.setTitle}
                            {...props}
                          />
                        )}
                      />
                    </Can>
                  </Can>

                  <Route
                    exact
                    path="/dev/acl"
                    render={props => (
                      <Acl setTitle={this.setTitle} {...props} />
                    )}
                  />
                  <Route exact path="/dev/palette" component={PaletteView} />
                  <Route
                    exact
                    path="/dev/acl-user"
                    render={props => (
                      <AclUser setTitle={this.setTitle} {...props} />
                    )}
                  />

                  <Can I="use module" a="calendar">
                    <Route
                      exact
                      path="/calendar"
                      render={props => (
                        <Calendar setTitle={this.setTitle} {...props} />
                      )}
                    />

                    <Can I="create" a="calendar">
                      <Route
                        exact
                        path="/calendar/form"
                        render={props => (
                          <CalendarForm setTitle={this.setTitle} {...props} />
                        )}
                      />
                      <Route
                        exact
                        path="/calendar/form/:calendarId"
                        render={props => (
                          <CalendarForm setTitle={this.setTitle} {...props} />
                        )}
                      />

                      <Route
                        exact
                        path="/calendar/event/form"
                        render={props => (
                          <CalendarEventForm
                            setTitle={this.setTitle}
                            {...props}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/calendar/event/form/:eventId"
                        render={props => (
                          <CalendarEventForm
                            setTitle={this.setTitle}
                            {...props}
                          />
                        )}
                      />
                    </Can>
                  </Can>
                  <Can I="use module" a="commConfiguration">
                    <Route
                      exact
                      path="/configuration"
                      render={props => (
                        <Configuration setTitle={this.setTitle} {...props} />
                      )}
                    />
                  </Can>

                  <Can I="use module" a="ticketsCommunication">
                    <Route
                      exact
                      path="/ticket-channels"
                      render={props => (
                        <TicketChannels setTitle={this.setTitle} {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/ticket-channels/:channelId"
                      render={props => (
                        <Tickets setTitle={this.setTitle} {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/ticket-channel/new"
                      render={props => (
                        <TicketChannelForm
                          setTitle={this.setTitle}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/ticket-channels/:channelId/edit"
                      render={props => (
                        <TicketChannelForm
                          setTitle={this.setTitle}
                          {...props}
                        />
                      )}
                    />
                  </Can>

                  <Can I="use module" a="communicationUsers">
                    <Route
                      exact
                      path="/users"
                      render={props => (
                        <Users setTitle={this.setTitle} {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/acl-management"
                      render={props => (
                        <AclList setTitle={this.setTitle} {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/groups"
                      render={props => (
                        <Groups setTitle={this.setTitle} {...props} />
                      )}
                    />
                  </Can>
                </>
              </Switch>
            </Toolbar>
          </BrowserRouter>
          <Alert
            open={alertOptions.open}
            type="swal"
            title={alertOptions.title}
            message={alertOptions.message}
            status={alertOptions.status}
            onClose={() => {
              alertOptions.onClose();
              this.onCloseAlert();
            }}
            loading={alertOptions.loading}
          />
        </AbilityContext.Provider>
      );

    return null;
  }
}

App.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  campuses: PropTypes.arrayOf(PropTypes.object),
  setCampuses: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

App.defaultProps = {
  campuses: null
};

const mapStateToProps = store => ({
  campuses: store.userState.campuses
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setCampuses }, dispatch);

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
