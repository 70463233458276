import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import CollapseGroup from "../../waybee-ui/CollapseGroup";
import ExternalLinks from "./components/ExternalLinks";
import ConfigurationService from "../../services/ConfigurationService";

class Configuration extends Component {
  constructor() {
    super();
    this.state = {
      links: []
    };
  }

  componentDidMount() {
    const { setTitle } = this.props;
    setTitle("Configurações");
    this.getLinks();
  }

  getLinks = async () => {
    const links = await ConfigurationService.getMenuLinks();

    const formattedLinks = links
      ? links.map((link, index) => ({
          id: index,
          name: link.name,
          link: link.action.value,
          icon: link.icon,
          public: link.public,
          menu: link.menu,
          destinationStudent: link.destinationStudent,
          destinationFinantial: link.destinationFinantial,
          destinationPedagogical: link.destinationPedagogical
        }))
      : [];

    this.setState({ links: formattedLinks });
  };

  saveLink = async data => {
    await ConfigurationService.saveExternalLinkMenu(data);
  };

  removeLink = async data => {
    await ConfigurationService.saveExternalLinkMenu(data);
  };

  render() {
    const { links } = this.state;
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CollapseGroup title="Links" startOpened>
            <ExternalLinks
              links={links}
              onRemoveLink={this.removeLink}
              onNewLink={this.saveLink}
            />
          </CollapseGroup>
        </Grid>
      </Grid>
    );
  }
}

Configuration.propTypes = {
  setTitle: PropTypes.func.isRequired
};

export default Configuration;
