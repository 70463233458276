import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _ from "lodash";
import { withAlert } from "react-alert";
import {
  Tabs,
  Tab as TabMui,
  TextField,
  InputAdornment,
  Slide,
  Button
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {
  AddCircleOutline as AddIcon,
  ArrowBack,
  Search as SearchIcon
} from "@material-ui/icons";
import { DebounceInput } from "react-debounce-input";
import Skeleton from "@material-ui/lab/Skeleton";
import { withTranslation } from "react-i18next";
import { AbilityContext, Can } from "../../../acl/can";
import ChatColumnHeader from "./ChatColumnHeader";
import ChatService from "../../../services/ChatService";
import ChatItem from "./ChatItem";
import ScrollColumnBody from "./ScrollColumnBody";
import ButtonBlock from "../../../waybee-ui/ButtonBlock";
import DialogTitle from "../../../waybee-ui/Dialog/DialogTitle";
import Dialog from "../../../waybee-ui/Dialog";
import DialogContent from "../../../waybee-ui/Dialog/DialogContent";
import DialogActions from "../../../waybee-ui/Dialog/DialogActions";

const SlideContainer = styled(Slide)`
  @media (min-width: 784px) {
    transition: none !important;
  }
`;
const Tab = styled(TabMui)`
  border-top: 2px solid ${({ theme }) => theme.color.background.default};
  border-bottom: 2px solid ${({ theme }) => theme.color.background.default};

  &:first-child {
    border-right: 2px solid ${({ theme }) => theme.color.background.default};
  }

  text-transform: none;
  font-size: 14px;
  padding-top: 16px;
  padding-bottom: 16px;
  color: rgba(0, 0, 0, 0.5);

  &.Mui-selected {
    color: rgba(0, 0, 0, 0.5);
  }

  min-width: 50px;
`;
const AddButton = styled(ButtonBlock)`
  border-top: 2px solid ${({ theme }) => theme.color.background.default};
  position: absolute;
  bottom: 0;
`;
const SearchField = styled(DebounceInput)`
  margin-top: 45px;
  margin-bottom: 15px;
`;
const EmptyList = styled.p`
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
`;

const TabText = styled.span`
  white-space: pre;
`;
const BackButton = styled(Button)`
  padding: 16px 22px;
  color: #787486;
`;

class ChatListColumn extends Component {
  performSearch = _.debounce(async () => {
    const { filter, page } = this.state;
    const { channelId, t } = this.props;

    try {
      const response = await ChatService.getChats(channelId, page, filter);

      this.setState({
        allChats: response.data,
        chatsNotAnswered: response.data.filter(chat => !chat.resolvedAt),
        hasMoreChats: response.hasMore,
        loadingChats: false,
        isSearching: false
      });
    } catch (e) {
      console.error(e);
      const { alert } = this.props;
      alert.show(
        t("messages.error_searching_conversations", "Erro ao buscar conversas"),
        { title: "Erro" }
      );
      this.setState({
        loadingChats: false,
        isSearching: false
      });
    }
  }, 500);

  constructor(props) {
    super(props);
    this.state = {
      loadingChats: true,
      loadingMoreChats: false,
      openResyncModal: false,
      chatsNotAnswered: [],
      allChats: [],
      selectedTab: 0,
      filter: "",
      page: 0,
      hasMoreChats: true,
      isSearching: false
    };
  }

  componentDidMount() {
    this.getChats();

    document.addEventListener("refresh-chat-item", this.refreshChatItem, false);
    document.addEventListener("refresh", this.refreshCallback, false);
  }

  componentDidUpdate(prevProps) {
    const { channelId } = this.props;

    if (channelId !== prevProps.channelId) {
      this.resetAndFetchChats();
    }
  }

  componentWillUnmount() {
    document.removeEventListener(
      "refresh-chat-item",
      this.refreshChatItem,
      false
    );
    document.removeEventListener("refresh", this.refreshCallback, false);
  }

  resetAndFetchChats = () => {
    const chatBody = document.getElementById("chat-body");
    if (chatBody) {
      chatBody.scrollTop = 0;
    }

    this.setState(
      {
        page: 0,
        hasMoreChats: true,
        loadingChats: true
      },
      () => {
        this.getChats();
      }
    );
  };

  refreshCallback = async () => {
    this.setState({ page: 0, hasMoreChats: true, loadingChats: true }, () =>
      this.getChats(true)
    );
  };

  refreshChatItem = async e => {
    const { msg, chatId, deleted, unread } = e.detail;
    const { allChats } = this.state;

    const chatIndex = allChats.findIndex(chat => chat.id === chatId);
    if (chatIndex === -1) return;

    let updatedChats = [...allChats];
    const updatedChat = { ...updatedChats[chatIndex] };

    if (!deleted) {
      updatedChat.lastMessage = {
        ...updatedChat.lastMessage,
        sender: {
          ...updatedChat.lastMessage.sender,
          name: localStorage.getItem("userName")
        },
        deleted: false,
        body: msg,
        date: new Date()
      };
      updatedChat.unread = unread;
    } else {
      updatedChat.lastMessage = {
        ...updatedChat.lastMessage,
        deleted: true,
        body: ""
      };
    }

    updatedChats[chatIndex] = updatedChat;

    updatedChats = _.orderBy(
      updatedChats,
      chat => chat.lastMessage && chat.lastMessage.date,
      ["desc"]
    );

    this.updateChats(updatedChats);
  };

  generateFormattedChat = (allChats, newChats) => {
    for (let i = 0; i < allChats.length; i += 1) {
      if (allChats[i].selected) {
        newChats.map(newChat => {
          const formattedChat = newChat;
          if (newChat.id === allChats[i].id) formattedChat.selected = true;
          return formattedChat;
        });
      }
    }
  };

  handleSearchChange = e => {
    const searchTerm = e.target.value;
    const chatBody = document.getElementById("chat-body");
    if (chatBody) {
      chatBody.scrollTop = 0;
    }

    this.setState(
      {
        filter: searchTerm,
        page: 0,
        isSearching: true,
        loadingChats: true
      },
      () => {
        this.performSearch();
      }
    );
  };

  getChats = async (refresh = false) => {
    this.setState({ loadingChats: true });
    const { t } = this.props;
    try {
      const { channelId } = this.props;
      const { allChats, page, filter } = this.state;
      const response = await ChatService.getChats(channelId, page, filter);
      const newChats = response.data;

      if (allChats.length && newChats.length && refresh) {
        this.generateFormattedChat(allChats, newChats);
      }

      this.setState({
        hasMoreChats: response.hasMore
      });

      this.updateChats(newChats, refresh);
    } catch (e) {
      console.error(e);
      const { alert } = this.props;
      alert.show(
        t("messages.error_fetching_conversations", "Erro ao buscar conversas"),
        { title: "Erro" }
      );
      this.setState({ loadingChats: false });
    }
  };

  loadMoreChats = async () => {
    const {
      hasMoreChats,
      loadingMoreChats,
      page,
      allChats,
      filter
    } = this.state;
    const { channelId, t } = this.props;

    if (!hasMoreChats || loadingMoreChats) return;

    this.setState({ loadingMoreChats: true });

    try {
      const nextPage = page + 1;
      const response = await ChatService.getChats(channelId, nextPage, filter);
      const newChats = response.data;

      this.generateFormattedChat(allChats, newChats);

      const updatedChats = [...allChats, ...newChats];

      const chatsNotAnswered = [];
      updatedChats.forEach(chat => {
        if (!chat.resolvedAt) chatsNotAnswered.push(chat);
      });

      this.setState({
        allChats: updatedChats,
        chatsNotAnswered,
        loadingMoreChats: false,
        page: nextPage,
        hasMoreChats: response.hasMore
      });
    } catch (e) {
      console.error(e);
      const { alert } = this.props;
      alert.show(
        t(
          "messages.error_loading_more_conversations",
          "Erro ao carregar mais conversas"
        ),
        { title: "Erro" }
      );
      this.setState({ loadingMoreChats: false });
    }
  };

  handleScroll = event => {
    const { hasMoreChats, loadingMoreChats, loadingChats } = this.state;

    if (hasMoreChats && !loadingMoreChats && !loadingChats) {
      const { target } = event;
      if (target.scrollHeight - target.scrollTop <= target.clientHeight + 100) {
        this.loadMoreChats();
      }
    }
  };

  updateChats = (chats, refresh = false) => {
    const { page, allChats } = this.state;
    const chatsNotAnswered = [];

    let updatedChats = chats;
    if (page > 0 && !refresh) {
      updatedChats = [...allChats, ...chats];
    }

    updatedChats.forEach(chat => {
      if (!chat.resolvedAt) chatsNotAnswered.push(chat);
    });

    this.setState({
      chatsNotAnswered,
      allChats: updatedChats,
      loadingChats: false,
      loadingMoreChats: false
    });
  };

  resolveList = (id, resolved) => {
    const { allChats } = this.state;
    const newChats = allChats.map(chat => {
      const newChat = chat;
      if (chat.id === id) newChat.resolvedAt = resolved ? new Date() : null;
      return newChat;
    });
    return newChats;
  };

  resyncChannel = async () => {
    const { channelId } = this.props;
    try {
      await ChatService.resyncChannel(channelId);
      this.getChats();
      this.setState({ openResyncModal: false });
    } catch (e) {
      console.error(e);
      const { alert } = this.props;
      alert.show(`Erro ao sincronizar chat\n${e.message}`, { title: "Erro" });
    }
  };

  onSelectChat = async (value, selectChat) => {
    const { t } = this.props;
    try {
      await ChatService.updateChatResolvedStatus(selectChat.id, value);

      const allChats = this.resolveList(selectChat.id, value);
      if (value) {
        this.setState({ allChats });
      } else {
        this.setState({
          chatsNotAnswered: allChats.filter(chat => !chat.resolvedAt)
        });
      }

      const onResolve = new Event("onResolve");
      onResolve.resolved = value;
      onResolve.roomId = selectChat.rocketChatId;
      document.dispatchEvent(onResolve);
    } catch (e) {
      console.error(e);
      const { alert } = this.props;
      alert.show(
        t("messages.error_updating_status", "Erro ao atualizar status"),
        { title: "Erro" }
      );
    }
  };

  onClickChat = selectedChat => {
    const { onSelect } = this.props;
    let { chatsNotAnswered, allChats } = this.state;

    chatsNotAnswered = chatsNotAnswered.map(chat => {
      const newChat = chat;
      if (selectedChat.id === chat.id) newChat.selected = true;
      else newChat.selected = false;
      return newChat;
    });

    allChats = allChats.map(chat => {
      const newChat = chat;
      if (selectedChat.id === chat.id) newChat.selected = true;
      else newChat.selected = false;
      return newChat;
    });

    this.setState({
      chatsNotAnswered,
      allChats
    });
    onSelect(selectedChat);
  };

  handleTabChange = (e, newTab) => {
    const chatBody = document.getElementById("chat-body");
    if (chatBody) {
      chatBody.scrollTop = 0;
    }

    this.setState({ selectedTab: newTab });
  };

  render() {
    const {
      selectedTab,
      chatsNotAnswered,
      allChats,
      filter,
      loadingChats,
      loadingMoreChats,
      openResyncModal,
      isSearching
    } = this.state;
    const { context } = this;

    const { onClickAdd, t, currentSection, onClose } = this.props;

    const isMobile = window.innerWidth <= 784;
    const isCurrentSection = isMobile ? currentSection === "chatList" : true;

    return (
      <SlideContainer direction="right" in={isCurrentSection} unmountOnExit>
        <Box position="relative" height="100%">
          <Box id="chat-header">
            {isMobile && (
              <BackButton onClick={onClose} startIcon={<ArrowBack />}>
                Voltar
              </BackButton>
            )}
            <ChatColumnHeader
              title={t("messages.conversations", "Conversas")}
              icon={context.can("create", "channel") ? "sync" : ""}
              onClickIcon={() => this.setState({ openResyncModal: true })}
            >
              <SearchField
                minLength={1}
                debounceTimeout={400}
                element={TextField}
                value={filter}
                onChange={this.handleSearchChange}
                placeholder={t(
                  "messages.search_student_placeholder",
                  "Procurar Aluno"
                )}
                margin="normal"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="action" />
                    </InputAdornment>
                  )
                }}
                fullWidth
              />
            </ChatColumnHeader>

            <Tabs
              value={selectedTab}
              onChange={this.handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              centered
              variant="fullWidth"
              selectionFollowsFocus={false}
            >
              <Tab
                wrapped
                variant="fullWidth"
                label={(
                  <TabText>
                    {t(
                      "messages.unanswered_messages",
                      "Mensagens\nnão respondidas"
                    )}
                  </TabText>
                )}
              />
              <Tab
                wrapped
                variant="fullWidth"
                label={(
                  <TabText>
                    {t("messages.all_messages", "Todas\nmensagens")}
                  </TabText>
                )}
              />
            </Tabs>
          </Box>

          <ScrollColumnBody
            bodyId="chat-body"
            subtractIds={["chat-header", "add-chat-btn"]}
            mb={52}
            onScroll={this.handleScroll}
          >
            {selectedTab === 0 && (
              <div>
                {(loadingChats || isSearching) &&
                chatsNotAnswered.length === 0 ? (
                  <Box display="flex" p={1.2}>
                    <Skeleton variant="circle" width={66} height={66} />
                    <Box
                      flexGrow={1}
                      ml={1}
                      display="flex"
                      justifyContent="center"
                      flexDirection="column"
                    >
                      <Skeleton variant="text" width="100%" />
                      <Skeleton variant="text" width="50%" height={10} />
                      <Skeleton variant="text" width="50%" height={10} />
                    </Box>
                  </Box>
                ) : (
                  <span>
                    {chatsNotAnswered.length ? (
                      chatsNotAnswered.map(chat => {
                        let title;
                        if (chat.type === "private") {
                          const membersFound = chat.members.find(
                            member => member.type === "family_member"
                          );
                          title =
                            (membersFound && membersFound.name) ||
                            t(
                              "messages.member_not_found",
                              "Membro não encontrado"
                            );
                        } else {
                          title = `Grupo - ${chat.student.name}`;
                        }
                        return (
                          <ChatItem
                            key={chat.id}
                            onClick={() => this.onClickChat(chat)}
                            onCheck={value => {
                              this.onSelectChat(value, chat);
                            }}
                            type={chat.type}
                            isResolved={!!chat.resolvedAt}
                            photo={chat.members[0] && chat.members[0].photo}
                            title={title}
                            studentName={chat.student.name}
                            studentInfos={`
                          RA${chat.student.ra}
                          ${
                            chat.student.classe
                              ? `- ${chat.student.classe}`
                              : ""
                          }
                          ${
                            chat.student.course
                              ? `- ${chat.student.course}`
                              : ""
                          }
                          ${chat.student.shift ? `- ${chat.student.shift}` : ""}
                        `}
                            message={
                              chat.lastMessage && {
                                body: chat.lastMessage.deleted
                                  ? t(
                                      "messages.this_message_has_been_deleted",
                                      "Essa mensagem foi apagada."
                                    )
                                  : chat.lastMessage.body,
                                date: chat.lastMessage.date,
                                sender: chat.lastMessage.sender.name
                              }
                            }
                            unread={chat.unread}
                            selected={chat.selected}
                          />
                        );
                      })
                    ) : (
                      <EmptyList>
                        {filter
                          ? t(
                              "messages.no_conversation_found",
                              "Nenhuma conversa encontrada"
                            )
                          : t(
                              "messages.no_conversation_here",
                              "Nenhuma conversa aqui"
                            )}
                      </EmptyList>
                    )}
                    {loadingMoreChats && (
                      <Box display="flex" p={1.2} justifyContent="center">
                        <Skeleton variant="circle" width={30} height={30} />
                      </Box>
                    )}
                  </span>
                )}
              </div>
            )}

            {selectedTab === 1 && (
              <div>
                {(loadingChats || isSearching) && allChats.length === 0 ? (
                  <Box display="flex" p={1.2}>
                    <Skeleton variant="circle" width={66} height={66} />
                    <Box
                      flexGrow={1}
                      ml={1}
                      display="flex"
                      justifyContent="center"
                      flexDirection="column"
                    >
                      <Skeleton variant="text" width="100%" />
                      <Skeleton variant="text" width="50%" height={10} />
                      <Skeleton variant="text" width="50%" height={10} />
                    </Box>
                  </Box>
                ) : (
                  <span>
                    {allChats.length ? (
                      allChats.map(chat => {
                        let title;
                        if (chat.type === "private") {
                          const membersFound = chat.members.find(
                            member => member.type === "family_member"
                          );
                          title =
                            (membersFound && membersFound.name) ||
                            t(
                              "messages.member_not_found",
                              "Membro não encontrado"
                            );
                        } else {
                          title = `Grupo - ${chat.student.name}`;
                        }
                        return (
                          <ChatItem
                            key={chat.id}
                            onClick={() => this.onClickChat(chat)}
                            onCheck={value => {
                              this.onSelectChat(value, chat);
                            }}
                            type={chat.type}
                            isResolved={!!chat.resolvedAt}
                            photo={chat.members[0] && chat.members[0].photo}
                            title={title}
                            studentName={chat.student.name}
                            studentInfos={`
                      RA${chat.student.ra}
                      ${chat.student.classe ? `- ${chat.student.classe}` : ""}
                      ${chat.student.course ? `- ${chat.student.course}` : ""}
                      ${chat.student.shift ? `- ${chat.student.shift}` : ""}
                    `}
                            message={
                              chat.lastMessage && {
                                body: chat.lastMessage.deleted
                                  ? t(
                                      "messages.this_message_has_been_deleted",
                                      "Essa mensagem foi apagada."
                                    )
                                  : chat.lastMessage.body,
                                date: chat.lastMessage.date,
                                sender: chat.lastMessage.sender.name
                              }
                            }
                            unread={chat.unread}
                            selected={chat.selected}
                          />
                        );
                      })
                    ) : (
                      <EmptyList>
                        {filter
                          ? t(
                              "messages.no_conversation_found",
                              "Nenhuma conversa encontrada"
                            )
                          : t(
                              "messages.no_conversation_here",
                              "Nenhuma conversa aqui"
                            )}
                      </EmptyList>
                    )}
                    {loadingMoreChats && (
                      <Box display="flex" p={1.2} justifyContent="center">
                        <Skeleton variant="circle" width={30} height={30} />
                      </Box>
                    )}
                  </span>
                )}
              </div>
            )}
          </ScrollColumnBody>

          <Can I="create" a="message">
            <AddButton
              id="add-chat-btn"
              size="small"
              startIcon={<AddIcon />}
              onClick={onClickAdd}
            >
              {t("messages.create_new_conversation", "Criar Nova Conversa")}
            </AddButton>
          </Can>

          <Dialog
            onClose={() => this.setState({ openResyncModal: false })}
            maxWidth="sm"
            fullWidth
            open={openResyncModal}
          >
            <DialogTitle>Resincronizar canal</DialogTitle>
            <DialogContent>
              Ao clicar em &quot;Resincronizar&quot;, você estará recarregando
              as informações do canal de conversa. Isso significa que todos os
              alunos que não se enquadram mais nos filtros aplicados a este
              canal serão removidos. Simultaneamente, quaisquer novos alunos que
              agora se enquadrem nos filtros serão adicionados ao canal.
              Certifique-se de que é isso que você deseja fazer antes de
              continuar
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.resyncChannel()}
              >
                Resincronizar
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => this.setState({ openResyncModal: false })}
              >
                Cancelar
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </SlideContainer>
    );
  }
}

ChatListColumn.contextType = AbilityContext;

ChatListColumn.propTypes = {
  channelId: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClickAdd: PropTypes.func.isRequired,
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired,
  t: PropTypes.func.isRequired,
  currentSection: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default withTranslation()(withAlert()(ChatListColumn));
