import { api } from "../utils/api";

const getChannels = async () => {
  const res = await api.get("Messages/channel");
  return res.data;
};

const getChannelsMember = async userId => {
  const res = await api.get("Messages/channel/getChannelsMember", {
    params: { userId }
  });
  return res.data;
};

const getChats = async (channelId, page = 0, search = "") => {
  const res = await api.get("Messages/chat", {
    params: { channelId, page, search }
  });
  return res.data;
};

const getMessages = async chatId => {
  const res = await api.get(`Messages/chat/${chatId}/getMessages`);
  return res.data;
};

const getMembers = async studentId => {
  const res = await api.get(`Messages/getFamilyMembersByStudent`, {
    params: { studentId }
  });
  return res.data;
};

const updateChatResolvedStatus = async (chatId, value) => {
  const res = await api.put(
    `Messages/chat/${chatId}/updateChatResolvedStatus`,
    {
      resolved: value
    }
  );
  return res.data;
};

const getClassesByChannel = async (channelId, year) => {
  const res = await api.get(`Messages/channel/${channelId}/getClasses`, {
    params: { year }
  });
  return res.data;
};

const getStudentsFilter = async (channelId, filters) => {
  const res = await api.get(`Messages/channel/${channelId}/getStudentsFilter`, {
    params: { ...filters }
  });
  return res.data;
};

const sendBroadcast = async (channelId, destinations, message) => {
  const data = {
    channelId,
    destinations: destinations.map(member => ({
      id: member.id,
      type: member.cardType,
      student: member.selectedStudent.id
    })),
    message
  };
  await api.post(`Messages/chat/newBroadcast`, { ...data });
};

const sendMessage = async (chatId, message) => {
  if (message.trim()) {
    const res = await api.post(`Messages/chat/${chatId}/sendMessage`, {
      message
    });
    return res.data;
  }
  return undefined;
};

const deleteMessage = async (chatId, msgId) => {
  const res = await api.delete(
    `/Messages/chat/${chatId}/deleteMessage/${msgId}`
  );
  return res.data;
};

const sendFile = async (chatId, formData) => {
  const res = await api.post(`Messages/chat/${chatId}/sendFile`, formData);
  return res.data;
};

const newFileBroadcast = async (chatId, formData) => {
  const res = await api.post(`Messages/chat/${chatId}/fileBroadcast`, formData);
  return res.data;
};

const getChatMembers = async chatId => {
  const res = await api.get(`Messages/chat/${chatId}/getMembers`);
  return res.data;
};

const authenticate = async () => {
  const res = await api.get(`Messages/authenticate`);
  return res.data;
};

const uploadChannelPhoto = async file => {
  const formData = new FormData();
  formData.append("fileToUpload", file);

  const res = await api.post("Messages/channel/uploadPhoto", formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
  return res.data;
};

const getEmployees = async () => {
  const res = await api.get("Messages/channel/getEmployees");
  return res.data;
};

const createChannel = async (values, channelId) => {
  const data = {
    name: values.name,
    photoAvatar: values.photoUrl,
    description: values.description,
    public: values.public,
    allowGroup: values.allowGroup,
    destinationFilter: {
      students: {}
    }
  };
  if (values.studentClasses && values.studentClasses.length)
    data.destinationFilter.students.classes = values.studentClasses;
  if (values.studentCourses && values.studentCourses.length)
    data.destinationFilter.students.courses = values.studentCourses;
  if (values.studentSeries && values.studentSeries.length)
    data.destinationFilter.students.grades = values.studentSeries;
  if (values.schoolYears && values.schoolYears.length)
    data.destinationFilter.students.years = values.schoolYears;
  if (values.employees && values.employees.length)
    data.destinationFilter.employees = values.employees;

  const res = channelId
    ? await api.put(`Messages/channel/${channelId}`, data)
    : await api.post(`Messages/channel`, data);

  return res.data;
};

const getChannelById = async id => {
  const res = await api.get(`Messages/channel/${id}`);
  return res.data;
};

const deleteChannel = async channelId => {
  const res = await api.delete(`Messages/channel/${channelId}`);
  return res.data;
};

const resyncChannel = async id => {
  const res = await api.post(`Messages/channel/${id}/resync`);
  return res.data;
};

const markAsRead = async (chatId, messageId) => {
  const res = await api.post(`Messages/chat/${chatId}/markAsRead`, {
    lastMessageId: messageId
  });
  return res.data;
};

const resyncAllChannels = async () => {
  const res = await api.post(`Messages/channel/resyncAll`);
  return res.data;
};

const forwardMessages = async (channelId, destinations, messages) => {
  const res = await api.post(`Messages/channel/${channelId}/forwardMessages`, {
    channelId,
    destinations,
    messages,
    isForwarding: true
  });
  return res.data;
};

export default {
  getChannels,
  getChannelsMember,
  getChats,
  getMessages,
  getMembers,
  updateChatResolvedStatus,
  getClassesByChannel,
  getStudentsFilter,
  sendBroadcast,
  getChatMembers,
  sendMessage,
  deleteMessage,
  sendFile,
  authenticate,
  uploadChannelPhoto,
  createChannel,
  getEmployees,
  getChannelById,
  deleteChannel,
  newFileBroadcast,
  resyncChannel,
  markAsRead,
  resyncAllChannels,
  forwardMessages
};
