import React, { useRef, useEffect } from "react";
import PropType from "prop-types";
import styled from "styled-components";
import {
  Icon as IconMui,
  Box,
  IconButton,
  Grid,
  TextField,
  InputAdornment
} from "@material-ui/core";
import { DebounceInput } from "react-debounce-input";
import {
  Search,
  KeyboardArrowUp,
  KeyboardArrowDown,
  Close
} from "@material-ui/icons";

const Container = styled.div`
  padding: 30px 20px;
`;

const Title = styled.p`
  font-size: 24px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.primary.dark};
  margin: 0;
`;

const HeaderButton = styled.div`
  width: 30px;
  height: 30px;
  background-color: ${({ theme }) => theme.color.primary.dark};
  border-radius: 50%;
  &:hover {
    cursor: pointer;
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Icon = styled(IconMui)`
  color: ${({ variant, theme }) =>
    variant === "filled" ? "white" : theme.color.primary.dark};
`;

const SearchContainer = styled.div`
  position: relative;
  margin-top: 16px;
`;

const SearchNavigation = styled.div`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
`;

const ChatColumnHeader = ({
  title,
  icon,
  onClickIcon,
  variant,
  children,
  resyncIcon,
  searchBar,
  onClickResync,
  onSearch,
  searchResults,
  onNextResult,
  onPrevResult,
  onClearSearch
}) => {
  const [inputValue, setInputValue] = React.useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    if (searchBar && inputRef.current) {
      setTimeout(() => {
        inputRef.current.querySelector("input").focus();
      }, 100);
    }
  }, [searchBar]);

  const handleClear = () => {
    setInputValue("");
    onClearSearch();
  };

  return (
    <Container>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Title>{title}</Title>

        <Grid
          container
          xs={3}
          justifyContent={resyncIcon ? "space-between" : "flex-end"}
          alignItems="center"
        >
          {resyncIcon && (
            <HeaderButton onClick={onClickResync}>
              <Icon fontSize="small" variant={variant}>
                {resyncIcon}
              </Icon>
            </HeaderButton>
          )}

          {icon && variant === "filled" && (
            <HeaderButton onClick={onClickIcon}>
              <Icon fontSize="small" variant={variant}>
                {icon}
              </Icon>
            </HeaderButton>
          )}
        </Grid>

        {icon && variant === "outline" && (
          <IconButton size="small" onClick={onClickIcon}>
            <Icon>{icon}</Icon>
          </IconButton>
        )}
      </Box>
      {searchBar && (
        <SearchContainer>
          <DebounceInput
            inputRef={inputRef}
            value={inputValue}
            debounceTimeout={400}
            element={TextField}
            onChange={e => {
              setInputValue(e.target.value);
              onSearch(e.target.value);
            }}
            placeholder="Pesquisar"
            margin="normal"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search color="action" />
                </InputAdornment>
              ),
              endAdornment: searchResults.length > 0 && (
                <InputAdornment position="end">
                  <SearchNavigation>
                    {searchResults.length > 1 && (
                      <>
                        <IconButton size="small" onClick={onPrevResult}>
                          <KeyboardArrowDown />
                        </IconButton>
                        <IconButton size="small" onClick={onNextResult}>
                          <KeyboardArrowUp />
                        </IconButton>
                      </>
                    )}
                    <IconButton size="small" onClick={handleClear}>
                      <Close />
                    </IconButton>
                  </SearchNavigation>
                </InputAdornment>
              )
            }}
            fullWidth
          />
        </SearchContainer>
      )}
      {children}
    </Container>
  );
};

ChatColumnHeader.defaultProps = {
  icon: "",
  onClickIcon: () => {},
  children: null,
  variant: "filled",
  resyncIcon: null,
  onClickResync: () => {},
  searchBar: false,
  onSearch: () => {},
  searchResults: [],
  onNextResult: () => {},
  onPrevResult: () => {},
  onClearSearch: () => {}
};

ChatColumnHeader.propTypes = {
  title: PropType.string.isRequired,
  icon: PropType.string,
  onClickIcon: PropType.func,
  variant: PropType.oneOf(["filled", "outline"]),
  // eslint-disable-next-line react/forbid-prop-types
  children: PropType.any, // Ejected prop
  resyncIcon: PropType.string,
  onClickResync: PropType.func,
  searchBar: PropType.bool,
  onSearch: PropType.func,
  searchResults: PropType.shape([]),
  onNextResult: PropType.func,
  onPrevResult: PropType.func,
  onClearSearch: PropType.func
};

export default ChatColumnHeader;
